import api from "service/api";

export function addEmployee(data) {
  return api.post(`/users`, data);
}
export function employeesListForUsers(page, search) {
  //
  return api.get(`/users/active?page=${page}&search=${search}`);
}

export function employeesListForAdmin(page) {
  return api.get(`/users?page=${page}`);
}

export function employeeListOptions() {
  return api.get(`/users/active?pageSize=1000`);
}

export function juniorEmployeeListOptionsForSuperAdmin(id) {
  return api.get(`/mentor/active?user=${id}&pageSize=1000`);
}

export function getSingleEmployeeData(id) {
  return api.get(`/users/${id}`);
}

export function activeEmployeeList(employeeStatus, page, search) {
  //
  return api.get(
    `/users?employeeStatus=${employeeStatus}&page=${page}&search=${search}`
  );
}

export function updateEmployeeStatus(id) {
  return api.patch(`/users/${id}`);
}

export function editEmployee(id, data) {
  return api.put(`/users/${id}`, data);
}

export function removeEmployee(id) {
  return api.delete(`/users/${id}`);
}

//***************************************for device token  */

export function addDeviceToken(id, data) {
  return api.put(`/users-devicetoken/${id}`, data);
}


//****************optional********** */

export function juniorEmployeeListOptions(id) {
  return api.get(`/mentor/active-option?user=${id}&pageSize=1000`);
}
export function employeesListForUsersForChat( search) {
  //
  return api.get(`/users/active-user-for-chat?search=${search}`);
}
///---------------------attendance------------
export function attendanceListForUsers(id,date) {
  return api.get(`/users-attendance?user=${id}&date=${date}`);
}


// ---------Profile api
export function getSingleEmployeeProfile(id) {
  return api.get(`/users-Profile/${id}`);
}


export function editProfile(id, data) {
  return api.put(`/edit-profile/${id}`, data);
}